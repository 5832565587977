$grid-breakpoints: (xxs: 0,
	xs: 450px,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1440px) !default;

$container-max-widths: (xs: 430px,
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
	xxl: 1400px) !default;

$base-font: 'Lato',
sans-serif;

$white: #fff;
$black: #000;
$base: #21201f;
$alt: #777777;
$gray: #cccccc;

$light: 300;
$normal: 400;
$bold: 700;
$xbold: 800;

$column: 100% / 12;